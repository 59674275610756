body {
  user-select: none!important;
}

.service_baloon_wrapp {
  display: inline-flex;
  width: 100%;

  @media (max-width: 991px) {
    gap: 0!important;
    flex-wrap: wrap;
  }

  .service_baloon {
    @media (max-width: 991px) {
      width: 60px!important;
      height: 60px!important;
    }

    svg {
      @media (max-width: 991px) {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.cs-hero {
  @media (max-width: 991px) {
    background-position: center!important;
  }
}

.service_baloon_wrapp .service_baloon {
  
  position: relative;
  background: #fff;
  border-radius: 50%;
  margin: 10px;
  width: 80px;
  height: 80px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.service_baloon_wrapp .tooltip {
  position: absolute;
  bottom: 0;
  height: fit-content;
  font-size: 14px;
  min-width: 120px;
  text-align: center;
  background: #fff;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.service_baloon_wrapp .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #fff;
  top: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.service_baloon_wrapp .service_baloon:hover .tooltip {
  bottom: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.service_baloon_wrapp .service_baloon:hover span,
.service_baloon_wrapp .service_baloon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.service_baloon_wrapp .Carro:hover .tooltip,
.service_baloon_wrapp .Carro:hover .tooltip::before {
  background: #1877F2;
  color: #fff;
}

.service_baloon_wrapp .Casa:hover .tooltip,
.service_baloon_wrapp .Casa:hover .tooltip::before {
  background: #FFCC29;
  color: #fff;
}

.service_baloon_wrapp .VidaSaude:hover .tooltip,
.service_baloon_wrapp .VidaSaude:hover .tooltip::before {
  background: #E4405F;
  color: #fff;
}

.service_baloon_wrapp .SeguroRcv:hover .tooltip,
.service_baloon_wrapp .SeguroRcv:hover .tooltip::before {
  background: #1877F2;
  color: #fff;
}

.service_baloon_wrapp .SeguroVida:hover .tooltip,
.service_baloon_wrapp .SeguroVida:hover .tooltip::before {
  background: #E4405F;
  color: #fff;
}

.service_baloon_wrapp .SeguroResidencial:hover .tooltip,
.service_baloon_wrapp .SeguroResidencial:hover .tooltip::before {
  background: #FFCC29;
  color: #fff;
}

.service_baloon_wrapp .SeguroAP:hover .tooltip,
.service_baloon_wrapp .SeguroAP:hover .tooltip::before {
  background: #E4405F;
  color: #fff;
}

.service_baloon_wrapp .SeguroAPP:hover .tooltip,
.service_baloon_wrapp .SeguroAPP:hover .tooltip::before {
  background: #E4405F;
  color: #fff;
}

.service_baloon_wrapp .BuscadeVeículos:hover .tooltip,
.service_baloon_wrapp .BuscadeVeículos:hover .tooltip::before {
  background: #1877F2;
  color: #fff;
}

.service_baloon_wrapp .BINRF:hover .tooltip,
.service_baloon_wrapp .BINRF:hover .tooltip::before {
  background: #E4405F;
  color: #fff;
}

.service_baloon_wrapp .PesquisadeRestrições:hover .tooltip,
.service_baloon_wrapp .PesquisadeRestrições:hover .tooltip::before {
  background: #E4405F;
  color: #fff;
}

.service_baloon_wrapp .PesquisadeDébito:hover .tooltip,
.service_baloon_wrapp .PesquisadeDébito:hover .tooltip::before {
  background: #E4405F;
  color: #fff;
}

.service_baloon_wrapp .PesquisadeProprietáriosAnteriores:hover .tooltip,
.service_baloon_wrapp .PesquisadeProprietáriosAnteriores:hover .tooltip::before {
  background: #E4405F;
  color: #fff;
}


// main css
.cs-slider_heading.cs-style1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cs-slider {
  position: relative;
}
.cs-slider.cs-style1 {
  display: flex;
  flex-wrap: wrap;
  .cs-slider_left {
    width: 44%;
    padding-right: 12%;
  }
  .cs-slider_right {
    width: 56%;
  }
}
.cs-slider.cs-style3 {
  width: 140%;
  margin-left: -20%;
  .cs-portfolio.cs-style1 {
    padding: 50px 0;
    transition: all 0.4s ease;
    .cs-portfolio_info_bg {
      left: -20px;
      top: 20px;
      opacity: 0;
      transition: all 0.4s ease;
    }
    .cs-portfolio_title {
      opacity: 0;
      left: 20px;
      transition: all 0.5s ease;
    }
    .cs-portfolio_subtitle {
      opacity: 0;
      left: 20px;
      transition: all 0.5s ease;
    }
  }
  .slick-center {
    .cs-portfolio.cs-style1 {
      padding: 0;
      .cs-portfolio_info_bg {
        left: 0px;
        top: 0px;
        opacity: 1;
        transition-delay: 0.4s;
      }
      .cs-portfolio_title {
        left: 0px;
        opacity: 1;
        transition-delay: 0.5s;
      }
      .cs-portfolio_subtitle {
        left: 0px;
        opacity: 1;
        transition-delay: 0.6s;
      }
    }
  }
}
.slick-slide > div {
  display: flex;
  flex-direction: column;
}

.cs-remove_overflow {
  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s linear;

    &.slick-active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.slick-dots {
  display: flex !important;
  justify-content: center;
  margin: 70px 0 0;
  list-style: none;
  padding: 0;

  li {
    margin: 0 8px;
    display: flex;
  }
  button {
    height: 16px;
    width: 16px;
    background-color: $accent;
    border: 1px solid $accent;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    overflow: hidden;
    color: transparent;
    &::before {
      content: '';
      position: absolute;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: $accent;
    }
  }
  .slick-active {
    button {
      background-color: transparent;
    }
  }
}

.cs-slider_arrows.cs-style1 {
  display: flex;
  margin: -8px;
  .slick-arrow {
    width: 60px;
    height: 35px;
    border: 2px solid currentColor;
    border-radius: 5px;
    margin: 8px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      color: $white;
      background-color: $accent;
      border-color: $accent;
    }
  }
}

.slick-initialized {
  cursor: ew-resize !important;
}
.cs-testimonial_slider_left {
  .slick-list {
    padding: 65px 0 !important;
  }
}

.cs-gap-24 {
  .slick-slide {
    padding-left: 12px;
    padding-right: 12px;
  }

  .slick-list {
    margin-left: -12px;
    margin-right: -12px;
  }
}

.cs-gap-12 {
  .slick-slide {
    padding-left: 6px;
    padding-right: 6px;
  }

  .slick-list {
    margin-left: -6px;
    margin-right: -6px;
  }
}

.cs-slider_controll {
  position: relative;
  display: flex;
  align-items: center;
  width: 320px;
  justify-content: space-between;
  .cs-controll_seperator {
    height: 1px;
    flex: 1;
    background-color: rgba($primary, 0.5);
    margin: 0 20px;
  }
  &.cs-type1 {
    margin-left: -205px;
  }
}
.slider-nav.cs-style1 {
  width: 405px;
  .slick-list {
    padding: 50px 0 !important;
  }
  .slick-dots,
  .slick-arrow {
    display: none !important;
  }
}
.slider-nav_item {
  width: 135px;
  transition: all 0.5s ease;
  opacity: 0.5;
  padding: 20px;
}
.slick-center .slider-nav_item {
  transform: scale(1.8);
  opacity: 1;
  position: relative;
  z-index: 2;
}

.cs-arrow_style1,
.cs-arrow_style2,
.cs-arrow_style3 {
  .slick-arrow {
    width: 60px;
    height: 35px;
    border: 2px solid $white;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;
    background-color: transparent;
    outline: none;
    position: absolute;
    bottom: 6px;
    z-index: 2;
    color: $primary;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    &:hover {
      background-color: $accent;
      border-color: $accent;
    }
  }
  .slick-prev {
    right: 76px;
  }
  .slick-next {
    right: 0;
  }
}
.cs-arrow_style2 {
  .slick-arrow {
    bottom: initial;
    top: -190px;
  }
}
.cs-arrow_style4 {
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 50px;
    width: 50px;
    background-color: rgba($ternary, 0.5);
    border-radius: 50%;
    z-index: 5;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    &:hover {
      background-color: $ternary;
      border-color: $accent;
    }
    &.slick-prev {
      left: 40px;
    }
    &.slick-next {
      right: 40px;
    }
  }
  .slick-prev {
    right: 76px;
  }
  .slick-next {
    right: 0;
  }
}
.cs-testimonial_slider {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .cs-testimonial_slider_left {
    width: 35%;
  }
  .cs-testimonial_slider_right {
    width: 65%;
  }
}
.swiper-pagination {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 52px;
  right: initial;
  top: initial;
  z-index: 2;

  .swiper-pagination-bullet {
    height: 16px;
    width: 16px;
    background-color: $accent;
    border: 1px solid $accent;
    border-radius: 50%;
    margin: 0 8px !important;
    cursor: pointer;
    position: relative;
    opacity: 1;
    transition: all 0.3s ease;
    &::before {
      content: '';
      position: absolute;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: $accent;
    }

    &.swiper-pagination-bullet-active {
      background-color: transparent;
    }
  }
  &.cs-square_shape {
    .swiper-pagination-bullet {
      height: 15px;
      width: 15px;
      border-radius: 3px;
      &::before {
        border-radius: 1px;
      }
    }
  }
}
.swiper-horizontal > .swiper-pagination,
.swiper-vertical > .swiper-pagination {
  right: initial;
  top: initial;
  transform: initial;
  left: 50%;
  transform: translateX(-50%);
  bottom: 52px;
  width: initial;
}
.swiper-vertical > .swiper-pagination {
  right: initial;
  top: initial;
}
.swiper-vertical > .swiper-pagination {
  left: 115px;
  bottom: initial;
  transform: translateY(-50%);
  top: 50%;
  flex-direction: column;
  align-items: flex-start;
  .swiper-pagination-bullet {
    display: block;
    height: initial;
    min-width: 170px;
    border: none;
    background-color: transparent;
    padding: 10px 20px;
    text-align: left;
    border-radius: 12px;
    margin: 15px 0 !important;
    font-size: 21px;
    font-weight: 600;
    color: #fff;
    &::before {
      display: none;
    }
    &.swiper-pagination-bullet-active {
      background: #E33E2B;
    }
  }
}
.cs-swiper_arrow_style_1 .swiper-button,
.cs-swiper_arrow_style_1 .swiper-button {
  position: absolute;
  z-index: 11;
  font-weight: 600;
  color: #fff;
  bottom: 48px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    color: $accent;
  }
  @media (max-width: 1199px) {
    bottom: 25px;
  }
  svg {
    font-size: 22px;
    fill: white;
  }
  &.image-swiper-button-prev {
    left: 115px;
    svg {
      margin-right: 12px;
    }
    @media (max-width: 1199px) {
      left: 15px;
    }
  }
  &.image-swiper-button-next {
    right: 115px;
    svg {
      margin-left: 12px;
    }
    @media (max-width: 1199px) {
      right: 15px;
    }
  }
}
@media screen and (max-width: 1380px) {
  .cs-arrow_style2 .slick-arrow {
    top: -175px;
  }
  .cs-slider.cs-style3 {
    width: 160%;
    margin-left: -30%;
  }
}
@media screen and (max-width: 1199px) {
  .cs-gap-50 {
    .slick-slide {
      padding-left: 15px;
      padding-right: 15px;
    }

    .slick-list {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  .cs-slider.cs-style1 {
    display: block;
    .cs-slider_left {
      width: 100%;
      padding-right: 0;
      padding-bottom: 90px;
    }
    .cs-slider_right {
      width: 100%;
    }
  }
  .cs-testimonial_slider {
    .cs-testimonial_slider_left {
      width: 45%;
    }
    .cs-testimonial_slider_right {
      width: 55%;
    }
  }
  .swiper-horizontal > .swiper-pagination,
  .swiper-vertical > .swiper-pagination {
    bottom: 30px;
  }
  .swiper-vertical > .swiper-pagination.cs-slide_numbers {
    left: 15px;
    .swiper-pagination-bullet {
      font-size: 16px;
      margin: 7px 0 !important;
    }
  }
  .swiper-vertical > .swiper-pagination {
    left: 15px;
  }
  .swiper-vertical > .swiper-pagination {
    flex-direction: row;
    top: initial;
    transform: translateX(-50%);
    left: 50%;
    bottom: 18px;
  }
  .swiper-vertical > .swiper-pagination .swiper-pagination-bullet {
    margin: 5px 6px !important;
    font-size: 16px;
  }
}
@media screen and (min-width: 992px) {
  .cs-hidden_desktop {
    display: none !important;
  }
}
@media screen and (max-width: 991px) {
  .cs-hidden_mobile {
    display: none !important;
  }
  .cs-pagination.cs-style1 {
    margin-top: 35px;
  }
  .cs-slider_nav {
    position: initial;
    width: 100%;
    transform: initial;
    padding-bottom: 80px;
  }
  .cs-gap-40,
  .cs-gap-50 {
    .slick-slide {
      padding-left: 15px;
      padding-right: 15px;
    }

    .slick-list {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  .cs-slider.cs-style1 .cs-slider_left {
    padding-bottom: 45px;
  }
  .cs-slider_heading.cs-style1 {
    flex-direction: column;
    align-items: flex-start;
    .cs-section_heading + .cs-slider_arrows {
      margin-top: 15px;
    }
  }
  .cs-testimonial_slider {
    display: block;
    .cs-testimonial_slider_left,
    .cs-testimonial_slider_right {
      width: 100%;
    }
    .cs-testimonial_slider_left {
      margin-bottom: 30px;
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .slider-nav.cs-style1 {
    margin: auto;
  }
  .slider-nav.cs-style1 .slick-list {
    padding: 40px 0 !important;
  }
  .slick-dots {
    margin: 40px 0 0;
  }
  .cs-slider.cs-style3 {
    width: 180%;
    margin-left: -40%;
  }
}

@media screen and (max-width: 767px) {
  .cs-slider.cs-style3 {
    width: 100%;
    margin-left: 0%;
    padding: 0 12px;
  }
}
@media screen and (max-width: 575px) {
  .cs-hide_mobile {
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .slider-nav.cs-style1 {
    width: 300px;
  }
  .slider-nav_item {
    width: 100px;
  }
  .cs-testimonial_slider .cs-testimonial.cs-style1 {
    padding-bottom: 70px;
  }
  .cs-arrow_style1,
  .cs-arrow_style2 {
    .slick-next {
      right: initial;
      left: 50%;
      transform: translateX(-50%);
      margin-left: 38px;
    }
    .slick-prev {
      right: initial;
      left: 50%;
      transform: translateX(-50%);
      margin-left: -38px;
    }
  }
  .cs-arrow_style2 {
    .slick-next,
    .slick-prev {
      bottom: 0;
      top: initial;
    }
  }
  .swiper-vertical > .swiper-pagination .swiper-pagination-bullet {
    margin: 5px 4px !important;
    font-size: 16px;
  }
}
.cs-vertical_slider {
  position: relative;
  height: 100vh;
}
.swiper {
  width: 100%;
  height: 100%;
}
