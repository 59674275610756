@tailwind base;
@tailwind components;
@tailwind utilities;

.stll_field {
    --tw-bg-opacity: 1;
    background-color: rgb(24 24 27 / var(--tw-bg-opacity))
}

.stll_field:focus {
    border-width: 0px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity))
}

.stll_field::placeholder {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

/* From Uiverse.io by Yaya12085 */ 
.radio-inputs {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    box-sizing: border-box;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
    padding: 0.25rem;
    width: 300px;
    font-size: 14px;
  }
  
  .radio-inputs .radio {
    flex: 1 1 auto;
    text-align: center;
  }
  
  .radio-inputs .radio input {
    display: none;
  }
  
  .radio-inputs .radio .name {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: none;
    padding: .5rem 0;
    color: #fff;
    transition: all .15s ease-in-out;
  }
  
  .radio-inputs .radio input:checked + .name {
    background-color: #fff;
    color: black;
    font-weight: 600;
  }

form {
    scrollbar-width: thin;
    scrollbar-color: #888 #333;
}

form::-webkit-scrollbar {
    width: 4px;
}

form::-webkit-scrollbar-track {
    background: #333;
}

form::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 12px;
    border: 3px solid #333;
}

form::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}