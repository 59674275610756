.container {
  @media (min-width: 1400px) {
    max-width: 1200px !important;
  }
}

.container {
  @media (min-width: 1280px) {
    max-width: 1160px !important;
  }
}

.homeslide_top {
  @media (max-width: 768px) {
    align-items: flex-start !important;
    padding-top: 6rem;
    text-align: center;
  }
}

.extra_div {
  @media (min-width: 768px) {
    display: none !important;
  }

  margin-top: 3rem;
}

.extra_wrapper {
  display: grid;
  grid-template-columns: 100px 1fr;
}

.extra_baloon {
  width: 70px;
  height: 70px;

  background: #ffc200;
  border-radius: 100%;
  border: 4px solid #dadada;
  box-shadow: inset 0px 10px 25px rgba(0, 0, 0, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

option {
  background-color: #181818;
}

.sidebar_nav {
  position: fixed;
  left: 0;
  top: 50%;
  margin-top: -250px;
  z-index: 1000;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  min-width: 70px;
  min-height: 500px;
  padding: 1.2rem 0.3rem;
  background: #292929;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 0px 14px 14px 0px;

  @media (max-width: 800px) {
    width: 100%;
    border-radius: 0;
    flex-direction: row;
    min-height: auto;
    bottom: 0;
    top: auto;
    padding: 1rem;
  }

  .active {
    background-color: #444444;
  }

  button {
    background: transparent;
    border: none;
    transition: 1s;
    border-radius: 100%;
    height: 52px;
    width: 52px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #444444;
    }

    svg {
      position: relative;
      z-index: -1;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .links_box {
    position: relative;
    transition: 0.5s;

    svg {
      pointer-events: none;
    }

    @media (max-width: 800px) {
      display: flex;
      flex-direction: row;
      margin: 0;
    }

    .sidemenu_intern_box {
      position: absolute;
      background: black;
      padding: 1.6rem;

      left: 61px;
      margin-right: -193px;

      gap: 3rem;

      transition: 0.5s;
      background-color: #292929;
      border-radius: 0 14px 14px 0;

      @media (max-width: 800px) {
        padding: 2.6rem;
      }

      .internBoxLinks {
        li {
          min-width: 200px;
        }
      }
    }

    .Assistencias {
      @media (max-width: 800px) {
        position: fixed;
        left: 0;
        bottom: 78px;
        margin-top: 212px;
        width: 100vw;
        display: flex;
        flex-direction: column;
      }
    }

    .Seguros {
      grid-template-columns: 1fr 1fr;
      gap: 1.2rem;
      top: 58px;

      @media (max-width: 800px) {
        position: fixed;
        left: 0;
        bottom: 0;
        top: auto;
        margin-bottom: 80px;
        border-radius: 0;
        width: 100vw;
        display: flex;
        flex-direction: column;
      }
    }

    .BBconsulta {
      grid-template-columns: 1fr 1fr;
      gap: 1.6rem;
      top: 172px;

      @media (max-width: 800px) {
        position: fixed;
        left: 0;
        top: auto;
        bottom: 0;
        margin-bottom: 80px;
        border-radius: 0;
        width: 100vw;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.backbutton_float_wrap {
  position: fixed;
  right: 0;
  bottom: 100px;
  z-index: 20;
}

.backbutton_float {
  position: relative;

  background: linear-gradient(#ffcc29, #ffcc29);
  border-radius: 50%;
  margin: 40px;
  width: 80px;
  height: 80px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.backbutton_float .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  min-width: 140px;
  text-align: center;
  background: #fff;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.backbutton_float .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #fff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.backbutton_float:hover {
  svg {
    color: white;
  }
}

.backbutton_float:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.backbutton_float:hover span,
.backbutton_float:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.backbutton:hover .tooltip,
.backbutton:hover .tooltip::before {
  background: #1877f2;
  color: #fff;
}

.searchButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e8e8e8;
  background-color: #212121;
  width: 40px;
  height: 40px;
  border: 2px solid #212121;
  border-radius: 10px;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: 5px 5px 2px rgba(0, 0, 0, 0.15), 2px 2px 2px rgba(0, 0, 0, 0.1),
    -2px -2px 1px rgba(255, 255, 255, 0.05);
  overflow: hidden;
  cursor: pointer;
}

.searchButton:active {
  box-shadow: inset 4px 4px 12px #1b1b1b, inset -4px -4px 12px #000;
}

.searchButton:disabled {
  box-shadow: inset 4px 4px 12px #1b1b1b, inset -4px -4px 12px #000;
}

.loader {
  display: block;
  font-size: 4px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

.hide_on_mobile {
  @media (max-width: 900px) {
    display: none !important;
  }
}

.text-justify {
  text-align: justify;
}

.cs_hero_assist {
  @media (max-width: 900px) {
    background-repeat: no-repeat;
  }
}

.cs-hero {
  height: 100%;
  background-size: cover;
  padding-left: 20rem;
  background-repeat: no-repeat;

  @media (max-width: 600px) {
    padding-left: 1rem;
    padding-top: 5rem;
  }

  .cs-hero_text {
    position: relative;
    z-index: 4;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;

    @media (max-width: 600px) {
      justify-content: flex-start;
    }

    h2 {
      font-size: 3rem;
      font-weight: bold;
    }
  }
  .cs-style5 {
    background-size: cover;
  }
}

.card_wrapp {
  background: #1e1e1e;
  border-radius: 6px;
  padding: 1rem;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    img {
      margin: -40px 0 20px 0;
      border-radius: 6px;
      height: 200px;
      width: 100%;
      object-fit: cover;
    }

    h5 {
      font-size: 24px;
      color: rgb(196, 196, 196);
      margin-bottom: 6px;
    }

    p {
      font-size: 16px;
      line-height: 1.6;
    }

    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;

      h5 {
        order: 1;
      }

      p {
        order: 2;
      }

      img {
        order: 3;
        margin: 0;
      }
    }
  }
}

.arrow_down {
  position: fixed;
  bottom: 40px;
  left: 50%;
  cursor: pointer;
  margin-left: -16px !important;
  z-index: 5;
  transform: translateY(-50%);
  animation: moveUpDown 3s infinite;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 2px 5px 10px #050505;
}

.menu_Btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: #e33e2b;
}

.menu_sign {
  width: 100%;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu_sign svg {
  width: 17px;
}

.menu_sign svg path {
  fill: white;
}

.menu_text {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: 14px;
  transition-duration: 0.3s;
}

.menu_Btn:hover {
  width: 200px;
  border-radius: 40px;
  transition-duration: 0.3s;
}

.menu_Btn:hover .menu_sign {
  width: 30%;
  transition-duration: 0.3s;
  padding-left: 20px;
}

.menu_Btn:hover .menu_text {
  opacity: 1;
  width: 80%;
  transition-duration: 0.3s;
  padding-right: 10px;
}

.menu_Btn:active {
  transform: translate(2px, 2px);
}

.menu_sign svg {
  @media (max-width: 800px) {
    display: none !important;
  }
}

.subs {
  @media (max-width: 800px) {
    top: -18px !important;
  }
}

.menu_text {
  @media (max-width: 800px) {
    width: 100% !important;
    text-align: left !important;
    padding-left: 20px !important;
    opacity: 1;
  }
}

.sub-circle {
  @media (max-width: 800px) {
    width: 200px !important;
    border-radius: 8px !important;
  }
}

.sub-circle .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  min-width: 120px;
  text-align: center;
  background: #fff;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.sub-circle .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #fff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.sub-circle .menu_baloon_label:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.sub-circle .menu_baloon_label:hover span,
.sub-circle .menu_baloon_label:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.sub-circle .Assistência24hs:hover .tooltip,
.sub-circle .Assistência24hs:hover .tooltip::before {
  background: #1877f2;
  color: #fff;
}

.sub-circle .CarroReserva:hover .tooltip,
.sub-circle .CarroReserva:hover .tooltip::before {
  background: #ffcc29;
  color: #fff;
}

.circle {
  display: block;
  position: relative;
  background: #fff;
  border-radius: 50%;
  margin: 10px;
  width: 80px;
  height: 80px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.circle:active {
  transform: scale(0.9);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
}

.circle:hover {
  cursor: pointer;
  background-color: #606fc7;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.3);
}

.circle .svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  transition: 0.5s;
  transform: rotate(180deg);
  fill: red;
}

.sub-circle {
  z-index: 200;
  position: absolute;
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 50%;
  transition: 0.3s;
  transform: scale(0.5);
  opacity: 0;
  padding: 0;
  margin: 0;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  border: none;
}

.sub-circle label {
  background-color: #e33e2b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: white;
  width: 100%;
  height: 100%;
  line-height: 3;
  transition: 0.5s;
}

.sub-circle label:hover {
  cursor: pointer;
}

.subs {
  left: -20px;
  top: -50px;
  width: 40px;
  height: 40px;
  text-align: center;
  z-index: 20;
  position: relative;
}

.hidden-sub-trigger {
  display: none;
}

.hidden-sub-trigger:checked ~ label {
  background-color: #606fc7;
}

.hidden-trigger {
  display: none;
}

.hidden-trigger:checked ~ .circle {
  transform: scale(0.9);
  cursor: pointer;
  background-color: #e3e3e3;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
}

.hidden-trigger:checked ~ .circle svg {
  transform: rotate(45deg);
}

.circle svg {
  transition: 0.5s;
}

.hidden-trigger:checked ~ .subs a:nth-of-type(1) {
  transform: translate(50px, -100px) scale(1);
  opacity: 1;
  transition: 0.1s;

  @media (max-width: 900px) {
    transform: translate(32px, 56px) scale(1);
  }
}

.hidden-trigger:checked ~ .subs a:nth-of-type(2) {
  transform: translate(100px, -66px) scale(1);
  opacity: 1;
  transition: 0.2s;

  @media (max-width: 900px) {
    transform: translate(32px, 106px) scale(1);
  }
}

.hidden-trigger:checked ~ .subs a:nth-of-type(3) {
  transform: translate(0px, -66px) scale(1);
  opacity: 1;
  transition: 0.3s;

  @media (max-width: 900px) {
    transform: translate(32px, 156px) scale(1);
  }
}

.hidden-trigger:checked ~ .subs a:nth-of-type(4) {
  transform: translate(-17px, -11px) scale(1);
  opacity: 1;
  transition: 0.4s;

  @media (max-width: 900px) {
    transform: translate(32px, 206px) scale(1);
  }
}

.wpp_float_wrap {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 2000;
}

.wpp_float {
  position: relative;

  background: linear-gradient(#30bf2f, #5af472);
  border-radius: 50%;
  margin: 40px;
  width: 80px;
  height: 80px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  svg {
    fill: white;
    width: 40px;
    height: 40px;
  }
}

.wpp_float .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  min-width: 140px;
  text-align: center;
  background: #fff;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wpp_float .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #fff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wpp_float:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wpp_float:hover span,
.wpp_float:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wpp:hover .tooltip,
.wpp:hover .tooltip::before {
  background: #1877f2;
  color: #fff;
}

.wpp_menu {
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
}

.wpp_menu .tooltip {
  position: absolute;
  left: 42px;
  bottom: -2px;
  height: fit-content;
  min-width: 232px;
  text-align: center;
  background: #fff;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wpp_menu .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #fff;
  bottom: 10px;
  left: 0;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wpp_menu:hover .tooltip {
  // top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wpp_menu:hover span,
.wpp_menu:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wpp_m:hover .tooltip,
.wpp_m:hover .tooltip::before {
  background: linear-gradient(#30bf2f, #5af472);
  color: #fff;
}

.nav_item {
  transition: 0.6s !important;

  &:hover {
    background: #e33e2b !important;
  }
}

.video_wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.6;
    z-index: 1;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

#videoBG {
  position: absolute;
  z-index: 0;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
}

.object-fit-cover {
  object-fit: cover !important;
}

.fileinput_field {
  display: block;
  width: 100%;
  // height: 100%;
  padding: 10px 20px;
  border-radius: 15px;
  outline: none;
  transition: all 0.3s ease;
  border: none;
  background-color: transparent;
  box-shadow: inset 2px 5px 10px #050505;
  color: #fff;

  label {
    padding: 6px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    background-color: white;
    color: black;
    margin-bottom: 10px;
    border-radius: 10px;

    transition: 0.6s;

    &:hover {
      color: #888888;
      background: #cccccc;
    }
  }
}

.cs-row_gap_20 {
  margin-left: -10px;
  margin-right: -10px;

  > div {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.radio-button-container {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-left: 10px;
}

.radio-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.radio-button__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.radio-button__label {
  display: inline-block;
  padding-left: 30px;
  margin-bottom: 10px;
  position: relative;
  font-size: 15px;
  color: #f2f2f2;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.radio-button__custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #555;
  transition: all 0.3s ease;
}

.radio-button__input:checked + .radio-button__label .radio-button__custom {
  background-color: #4c8bf5;
  border-color: transparent;
  transform: scale(0.8);
  box-shadow: 0 0 20px #4c8bf580;
}

.radio-button__input:checked + .radio-button__label {
  color: #4c8bf5;
}

.radio-button__label:hover .radio-button__custom {
  transform: scale(1.2);
  border-color: #4c8bf5;
  box-shadow: 0 0 20px #4c8bf580;
}

.solicitated_alert {
  background: black;
  border-radius: 10px;
  color: white;
  padding: 2rem;
  margin-bottom: 4rem;
}

.benefit_btn {
  display: grid;
  place-items: center;
  background: #171717;
  padding: 1.4em;
  border-radius: 10px;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: transform 0.5s;

  &:hover {
    box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
      inset -4px -4px 6px -1px rgba(0, 0, 0, 0.7),
      -0.5px -0.5px 0px rgba(0, 0, 0, 1), 0.5px 0.5px 0px rgba(0, 0, 0, 0.15),
      0px 12px 10px -10px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
    transform: translateY(0.5em);

    svg {
      transform: scale(0.9);
      fill: #333333;
    }

    .benefit_btn_tooltip {
      top: -70%;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      scale: 1;
      animation: shake 0.5s ease-in-out both;
    }
  }

  svg {
    transition: transform 0.5s;
  }

  .benefit_btn_tooltip {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.3em 0.6em;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    background: #171717;
    z-index: 5;
    border-radius: 8px;
    scale: 0;
    transform-origin: 0 0;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;

    &::before {
      position: absolute;
      content: "";
      height: 0.6em;
      width: 0.6em;
      bottom: -0.2em;
      left: 50%;
      transform: translate(-50%) rotate(45deg);
      background: #171717;
    }
  }
}

.benefit_active {
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 5px -1px #0987ff, -0.5px -0.5px 0px rgba(0, 0, 0, 1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 12px 10px -10px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  transform: translateY(0.5em);
}

.cs-light {
  font-weight: 300;
}

.cs-normal {
  font-weight: 400;
}

.cs-medium {
  font-weight: 500;
}

.cs-semi_bold {
  font-weight: 600;
}

.cs-bold {
  font-weight: 700;
}

.cs-extra_bold {
  font-weight: 800;
}

.cs-black {
  font-weight: 900;
}

.cs-radius_3 {
  border-radius: 3px;
}

.cs-radius_5 {
  border-radius: 5px;
}

.cs-radius_7 {
  border-radius: 7px;
}

.cs-radius_10 {
  border-radius: 10px;
}

.cs-radius_15 {
  border-radius: 15px;
}

.cs-line_height_1 {
  line-height: 1.2em;
}

.cs-line_height_2 {
  line-height: 1.25em;
}

.cs-line_height_3 {
  line-height: 1.3em;
}

.cs-line_height_4 {
  line-height: 1.4em;
}

.cs-line_height_5 {
  line-height: 1.5em;
}

.cs-line_height_6 {
  line-height: 1.6em;
}

.cs-line_height_7 {
  line-height: 1.7em;
}

.cs-font_14 {
  font-size: 14px;
}

.cs-font_16 {
  font-size: 16px;
}

.cs-font_18 {
  font-size: 18px;
}

.cs-font_20 {
  font-size: 20px;
}

.cs-font_22 {
  font-size: 22px;
}

.cs-font_28 {
  font-size: 28px;
}

.cs-font_30 {
  font-size: 28px;
}

.cs-font_38 {
  font-size: 38px;
}

.cs-font_42 {
  font-size: 42px;
}

.cs-font_50 {
  font-size: 50px;
}

.cs-mp0 {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cs-m0 {
  margin: 0;
}

hr {
  margin: 0;
  padding: 0;
  border: none;
  border-top: 1px solid #f2f1ff;
}

.cs-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.cs-vertical_middle {
  display: flex;
  align-items: center;
  min-height: 100%;
}

.cs-vertical_middle_in {
  flex: none;
  width: 100%;
}

.cs-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cs-white_color,
.cs-white_color_hover:hover {
  color: $white;
}

.cs-primary_color {
  color: $primary;
}

.cs-ternary_color {
  color: $ternary;
}

.cs-accent_color,
.cs-accent_color_hover:hover {
  color: $accent;
}

.cs-white_bg {
  background-color: #fff;
}

.cs-gray_bg {
  background-color: $gray;
}

.cs-accent_bg_1,
.cs-accent_bg_1_hover:hover {
  background-color: rgba($accent, 0.01);
}

.cs-accent_bg_2,
.cs-accent_bg_2_hover:hover {
  background-color: rgba($accent, 0.02);
}

.cs-accent_bg_3,
.cs-accent_bg_3_hover:hover {
  background-color: rgba($accent, 0.03);
}

.cs-accent_bg_4,
.cs-accent_bg_4_hover:hover {
  background-color: rgba($accent, 0.04);
}

.cs-accent_bg_5,
.cs-accent_bg_5_hover:hover {
  background-color: rgba($accent, 0.05);
}

.cs-accent_bg_6,
.cs-accent_bg_6_hover:hover {
  background-color: rgba($accent, 0.06);
}

.cs-accent_bg_7,
.cs-accent_bg_7_hover:hover {
  background-color: rgba($accent, 0.07);
}

.cs-accent_bg_8,
.cs-accent_bg_8_hover:hover {
  background-color: rgba($accent, 0.08);
}

.cs-accent_bg_9,
.cs-accent_bg_9_hover:hover {
  background-color: rgba($accent, 0.09);
}

.cs-accent_bg_10,
.cs-accent_bg_10_hover:hover {
  background-color: rgba($accent, 0.1);
}

.cs-accent_bg_15,
.cs-accent_bg_15_hover:hover {
  background-color: rgba($accent, 0.15);
}

.cs-accent_bg_20,
.cs-accent_bg_20_hover:hover {
  background-color: rgba($accent, 0.2);
}

.cs-accent_bg_25,
.cs-accent_bg_25_hover:hover {
  background-color: rgba($accent, 0.25);
}

.cs-accent_bg_30,
.cs-accent_bg_30_hover:hover {
  background-color: rgba($accent, 0.3);
}

.cs-accent_bg_35,
.cs-accent_bg_35_hover:hover {
  background-color: rgba($accent, 0.35);
}

.cs-accent_bg_40,
.cs-accent_bg_40_hover:hover {
  background-color: rgba($accent, 0.4);
}

.cs-accent_bg_50,
.cs-accent_bg_50_hover:hover {
  background-color: rgba($accent, 0.5);
}

.cs-accent_bg_60,
.cs-accent_bg_60_hover:hover {
  background-color: rgba($accent, 0.6);
}

.cs-accent_bg_70,
.cs-accent_bg_70_hover:hover {
  background-color: rgba($accent, 0.7);
}

.cs-accent_bg_80,
.cs-accent_bg_80_hover:hover {
  background-color: rgba($accent, 0.8);
}

.cs-accent_bg_90,
.cs-accent_bg_90_hover:hover {
  background-color: rgba($accent, 0.9);
}

.cs-accent_bg,
.cs-accent_bg_hover:hover {
  background-color: $accent;
}

.cs-primary_bg,
.cs-primary_bg_hover:hover {
  background-color: $primary;
}

.cs-gradient_bg_1 {
  background: linear-gradient(267.18deg, #161616 0%, #080808 100%);
}

.cs-text_btn {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  color: $white;
  cursor: pointer;

  svg {
    margin-left: 15px;
    font-size: 24px;
  }

  span {
    display: inline-block;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: currentColor;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-duration: 0.6s;
      transition-timing-function: ease-out;
    }
  }

  &:hover {
    span {
      &::before {
        transform: scaleX(1);
        transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      }
    }
  }

  &.cs-type1 {
    flex-direction: row-reverse;

    svg {
      margin-left: 0px;
      margin-right: 15px;
      transform: rotate(180deg);
    }

    span {
      &::before {
        left: initial;
        right: -100%;
        transform: scaleX(0) rotateY(180deg);
      }
    }

    &:hover {
      span {
        &::before {
          transform: scaleX(1) rotateY(180deg);
        }
      }
    }
  }

  &.cs-type2 {
    flex-direction: row-reverse;

    svg {
      margin-left: 0;
      margin-right: 15px;
      color: $accent;
    }
  }
}

.cs-btn.cs-style1 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 26px;
  transition: all 0.3s ease;
  border: transparent;
  color: $primary;
  position: relative;
  line-height: 1.5em;
  font-weight: 600;
  border-radius: 15px;
  background-color: $accent;
  color: #fff;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, 0.4);
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.6s;
    transition-timing-function: ease-out;
    border-radius: inherit;
  }

  svg {
    margin-left: 14px;
  }

  > * {
    position: relative;
    z-index: 1;
  }

  &:hover {
    color: #ff4a16;
    // &:before {
    //   transform: scaleX(1);
    //   transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    // }
  }

  &.cs-type1 {
    border: 2px solid #fff;
    background-color: transparent;
    border-radius: 40px;
    transition: all 0.6s ease-out;
    font-size: 14px;

    &::before {
      background-color: #fff;
      border-radius: 40px;
    }

    span {
      margin-left: 10px;
    }
  }
}

.cs-page_navigation {
  > div {
    position: relative;

    &:not(:last-child) {
      margin-right: 21px;
      padding-right: 21px;

      &::after {
        content: "";
        height: 22px;
        width: 2px;
        background-color: #fff;
        display: inline-block;
        position: absolute;
        right: -1px;
        top: 3px;
      }
    }
  }
}

.cs-pagination_box {
  margin: -7px;

  .cs-pagination_item {
    margin: 7px;
  }
}

.cs-pagination_item {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  background-color: #000;

  &.active {
    background-color: $accent;
    color: #fff;
    pointer-events: none;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out forwards;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cs-form_field {
  display: block;
  width: 100%;
  padding: 10px 20px;
  border-radius: 15px;
  outline: none;
  transition: all 0.3s ease;
  border: none;
  background-color: transparent;
  box-shadow: inset 2px 5px 10px rgb(5, 5, 5);
  color: #fff;

  &:focus {
    border-color: $accent;
  }

  &:disabled {
    color: #777777 !important;
  }
}

.form_field_label {
  margin-left: 10px;
  font-size: 14px;
  color: #8f8f8f;
}

label {
  margin-bottom: 12px;
}

.cs-right_full_width {
  width: calc(50vw - 12px);
  padding-right: 24px;
}

.cs-rating {
  width: 136px;
  height: 24px;
  position: relative;

  > * {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .cs-rating_bg {
    opacity: 0.2;
  }

  .cs-rating_percentage {
    width: 50%;
  }
}

.cs-post_meta.cs-style1 {
  display: flex;

  > * {
    position: relative;
    display: inline-block;

    &:not(:last-child) {
      margin-right: 12px;
      padding-right: 12px;

      &::before {
        content: "";
        height: 20px;
        width: 2px;
        border-radius: 2px;
        background-color: #fff;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -10px;
      }
    }
  }
}

/* Start Accordion */
.cs-accordian_title {
  font-size: 22px;
  margin-bottom: 0;
  transition: all 0.3s ease;

  @media (max-width: 991px) {
    font-size: 18px;
  }
}

.cs-accordian_body {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);

  p {
    margin: 0;
  }
}

.cs-accordian_body_in {
  line-height: 1.8em;
  padding-top: 15px;
}

.cs-accordian_head {
  padding: 2px 20px 2px 0;
  position: relative;
  cursor: pointer;

  &:hover {
    .cs-accordian_title {
      color: $accent;
    }
  }
}

.cs-accordian {
  border-bottom: 2px solid $accent;
  padding-bottom: 25px;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  @media (max-width: 991px) {
    padding-bottom: 15px;
    border-width: 1px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.cs-accordian_toggle {
  display: flex;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 0;
  transition: all 0.3s ease;
}

.cs-accordian.active {
  .cs-accordian_toggle {
    transform: rotate(180deg);
  }

  .cs-accordian_head {
    pointer-events: none;
  }

  .cs-accordian_body {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
  }
}

/* End Accordion */
#cs-result {
  line-height: 2.8em;
}

.cs-parallax {
  > *:not([class*="cs-shape_"]) {
    position: relative;
    z-index: 5;
  }
}

.cs-fullscreen_swiper_wrap {
  position: relative;
  height: 100vh;
}

.cs-fullscreen_swiper_slider {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1399px) {
  .cs-right_space_150 {
    padding-right: 50px;
  }
}

@media screen and (max-width: 1199px) {
  .cs-right_space_40,
  .cs-right_space_150 {
    padding-right: 0;
  }
}

@media screen and (max-width: 991px) {
  .cs-left_space_30 {
    padding-left: 0px;
  }

  .cs-font_14_sm {
    font-size: 14px;
  }

  .cs-font_16_sm {
    font-size: 16px;
  }

  .cs-font_18_sm {
    font-size: 18px;
  }

  .cs-font_20_sm {
    font-size: 20px;
  }

  .cs-font_22_sm {
    font-size: 22px;
  }

  .cs-font_24_sm {
    font-size: 24px;
  }

  .cs-font_26_sm {
    font-size: 26px;
  }

  .cs-font_28_sm {
    font-size: 28px;
  }

  .cs-font_42_sm {
    font-size: 42px;
  }

  .cs-font_36_sm {
    font-size: 36px;
  }

  .cs-btn_group > *:not(:last-child) {
    margin-right: 10px;
  }

  .flex-column-reverse-lg {
    flex-direction: column-reverse;
  }

  .cs-seciton_heading.cs-style1 .cs-section_title::before {
    top: 1px;
  }
}

.cs-partner_logo_wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 -20px;
}

.cs-partner_logo {
  padding: 20px;
}

.cs-video_block_1_wrap {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    height: 50%;
    width: 100%;
    left: 0%;
    top: 0%;
    background-color: #000;
  }
}

.cs-video_block.cs-style1 {
  display: block;
  height: 725px;
  position: relative;
  z-index: 10;
  overflow: hidden;
  border-radius: 20px;

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    background-color: rgba($black, 0.4);
    transition: all 0.4s ease;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  &.cs-size1 {
    height: 580px;
  }
}

.cs-player_btn {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cs-player_btn:before,
.cs-player_btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 50%;
}

.cs-player_btn:before {
  z-index: 0;
  animation: pulse-border 1500ms ease-out infinite;
}

.cs-player_btn:after {
  z-index: 1;
  transition: all 200ms;
}

.cs-player_btn span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 24px solid currentColor;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.cs-fixed_bg {
  background-attachment: fixed;
}

.cs-parallax [class*="cs-to_"],
.cs-parallax_bg [class*="cs-to_"] {
  transition: all 0.6s ease;
}

.cs-section_heading.cs-style1 {
  position: relative;
  z-index: 2;

  .cs-section_subtitle {
    color: $ternary;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .cs-section_title {
    font-size: 50px;
    margin-bottom: 0;
  }

  .cs-separator {
    height: 2px;
    width: 50px;
    border-radius: 2px;
  }
}

.cs-hover_layer,
.cs-hover_layer1,
.cs-hover_layer3 {
  position: relative;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: perspective(600px) translate3d(0, 0, 0);
}

@keyframes particalAnimation {
  0%,
  100% {
    transform: translate(0px, 0px);
  }

  20% {
    transform: translate(80px, -10px);
  }

  40% {
    transform: translate(140px, 70px);
  }

  60% {
    transform: translate(90px, 130px);
  }

  80% {
    transform: translate(-40px, 80px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes animo-x {
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(44px);
  }
}

@keyframes light-mode {
  50% {
    opacity: 0.5;
  }
}

@keyframes animo-y {
  50% {
    animation-timing-function: cubic-bezier(0.55, 0, 0.2, 1);
    transform: translateY(30px);
  }
}

@keyframes rotate-anim {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes semi-rotate-anim {
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(25px) rotate(-53deg);
  }
}

@keyframes up-down-anim {
  40% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(40px) rotate(-1deg);
  }
}

@keyframes swing-anim {
  65% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(-48px) rotate(-75deg);
  }
}

.cs-shape_1 {
  width: 0px;
  height: 0px;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 14px solid $accent;
}

.cs-shape_wrap_1 {
  position: relative;

  .cs-shape_1 {
    position: absolute;

    &:nth-child(1) {
      top: 25%;
      left: 10%;
      animation: semi-rotate-anim 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }

    &:nth-child(2) {
      top: 30%;
      left: 72%;
      transform: rotate(-15deg);
      animation: semi-rotate-anim 8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }

    &:nth-child(3) {
      top: 68%;
      left: 35%;
      transform: rotate(-30deg);
      animation: animo-x 9s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }

  &.cs-position_1 {
    .cs-shape_1 {
      &:nth-child(1) {
        top: 15%;
        left: 15%;
      }

      &:nth-child(2) {
        top: 35%;
        left: 85%;
      }

      &:nth-child(3) {
        top: 85%;
        left: 35%;
      }
    }
  }
}

.cs-shape_2 {
  position: absolute;
  height: 320px;
  width: 320px;

  div {
    background-color: #000;
    transform: rotate(45deg);
    height: 100%;
    width: 100%;
  }
}

.cs-shape_wrap_2 {
  position: relative;

  .cs-shape_2 {
    right: -10%;
    top: 12%;
    animation: animo-x 9s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

.cs-shape_wrap_3 {
  position: relative;

  .cs-shape_3 {
    animation: animo-y 5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    position: absolute;
    right: 20px;
    top: -15%;
  }
}

.cs-shape_wrap_4 {
  position: relative;

  .cs-shape_4 {
    position: absolute;
    background-color: #000;
    border-radius: 50%;

    &:nth-child(1) {
      left: 5%;
      top: 42%;
      height: 100px;
      width: 100px;
      animation: animo-y 8s infinite;
    }

    &:nth-child(2) {
      left: 15%;
      top: 62%;
      height: 286px;
      width: 286px;
    }
  }
}

.cs-shape_wrap_5 {
  position: relative;

  .cs-section_heading {
    position: relative;
    z-index: 2;
  }

  .cs-shape_1 {
    position: absolute;

    &:nth-child(1) {
      top: 25%;
      left: 58%;
      animation: semi-rotate-anim 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }

    &:nth-child(2) {
      top: 40%;
      left: 85%;
      transform: rotate(-15deg);
      animation: semi-rotate-anim 8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }

    &:nth-child(3) {
      top: 85%;
      left: 72%;
      transform: rotate(-30deg);
      animation: animo-x 9s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }
}

.cs-shape_wrap_6 {
  position: relative;
  overflow: hidden;

  > .container {
    position: relative;
    z-index: 3;
  }

  .cs-shape_2 {
    > div {
      background-color: #181818;
    }

    &:nth-child(1) {
      left: -240px;
      top: 30px;
      animation: animo-x 9s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }

    &:nth-child(2) {
      bottom: 30px;
      right: -200px;
      animation: animo-x 9s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }
}

.cs-contact_info.cs-style1 {
  svg {
    position: absolute;
    left: 0;
    top: 4px;
    width: 20px;
    max-height: 20px;
  }

  li {
    position: relative;
    padding-left: 35px;

    &:not(:last-child) {
      margin-bottom: 22px;
    }
  }
}

.cs-page_heading.cs-style1 {
  height: 350px;
  padding: 120px 0 50px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: -30px;
    top: -30px;
    height: 151px;
    width: 151px;
    background: $accent;
    opacity: 0.8;
    filter: blur(125px);
  }

  > * {
    position: relative;
    z-index: 2;
  }

  .cs-page_heading_in {
    display: inline-block;
  }

  .breadcrumb {
    justify-content: flex-end;
    margin-bottom: 0;
    color: $ternary;
  }

  .breadcrumb-item.active {
    color: $white;
  }

  .cs-page_title {
    margin-bottom: 18px;
  }
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 14px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "";
  height: 17px;
  width: 1px;
  background-color: $white;
  padding: 0;
  position: relative;
  top: 5px;
  margin-right: 14px;
}

.cs-image_layer.cs-style1 {
  padding-right: 60px;
  padding-bottom: 60px;

  img {
    position: relative;
    z-index: 2;
  }

  .cs-image_layer_in {
    position: relative;
    border-radius: 15px;

    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      right: -60px;
      bottom: -60px;
      background-color: $accent;
      border-radius: inherit;
    }
  }

  &.cs-size_md {
    padding-right: 20px;
    padding-bottom: 20px;

    .cs-image_layer_in {
      &::after {
        right: -20px;
        bottom: -20px;
      }
    }
  }
}

.cs-tab {
  display: none;

  &.active {
    display: block;
  }
}

.cs-tabs.cs-style1 {
  .cs-tabs_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.cs-tab_links.cs-style1 {
  display: inline-flex;
  padding: 5px;
  background-color: #000;
  border-radius: 10px;
  position: absolute;
  top: -195px;
  right: 0;
  z-index: 5;

  li {
    padding: 7px 20px;
    font-weight: 500;
    color: #fff;
    display: inline-flex;
    transition: all 0.3s ease;
    cursor: pointer;

    &.active {
      background-color: $accent;
      border-radius: 10px;
      pointer-events: none;
    }
  }

  @media (max-width: 1380px) {
    top: -185px;
  }

  @media (max-width: 991px) {
    top: -128px;
  }

  @media (max-width: 767px) {
    margin-bottom: 40px;
    position: static;
  }
}

.cs-google_map {
  height: 600px;

  iframe {
    height: 100%;
    display: block;
    border: none;
    width: 100%;
    filter: grayscale(100%) invert(90%) contrast(120%);
  }

  &.cs-type1 {
    height: 100%;
    min-height: 300px;
    width: 50vw;
  }
}

.cs-list.cs-style1 {
  li {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.cs-shine_hover_1 {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -80%;
    z-index: 2;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(255, 255, 255, 0.15) 100%
    );
    transform: skewX(-25deg);
    z-index: 1;
  }

  &:hover {
    &::before {
      animation: shine 1.3s;
    }
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.cs-rotate_img {
  transform: rotate(45deg);
  overflow: hidden;
  border-radius: 7px;
}

.cs-rotate_img_in {
  transform: rotate(-45deg) scale(1.4);
}

.cs-half_screen {
  width: 56vw;
}

.lg-backdrop {
  background-color: #181818;
}

.lg-toolbar .lg-icon,
#lg-counter,
.lg-actions .lg-next,
.lg-actions .lg-prev {
  color: #fff;
}

.lg-toolbar .lg-icon:hover,
.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover {
  color: rgba(255, 255, 255, 0.7);
}

.cs-scrollup {
  position: fixed;
  bottom: -60px;
  right: 40px;
  color: #fff;
  padding: 5px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s ease;
  z-index: 10;
  background-color: #000000;
  color: $accent;
  box-shadow: 0px 1px 5px 1px rgba($black, 0.2);
  border-radius: 50%;

  &:hover {
    background-color: $accent;
    color: $white;
  }
}

.cs-scrollup.cs-scrollup_show {
  bottom: 50px;
}

.cs-case_study {
  position: relative;
  padding: 150px 120px;

  .cs-case_study_bg {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .cs-accent_bg {
      flex: 1;
    }

    .cs-bg {
      flex: 2;
    }
  }

  .cs-case_study_title {
    background: rgba($gray, 0.9);
    border-radius: 15px;
    margin: 0;
    position: relative;
    z-index: 10;
    font-weight: 700;
    font-size: 120px;
    line-height: 1.25em;
    color: #fefefe;
    display: inline-flex;
    padding: 40px 150px;

    a {
      color: inherit;
      text-decoration: underline;
      text-decoration-color: transparent;
      text-decoration-skip-ink: none;
      text-decoration-thickness: 0.07em;
      text-underline-position: under;
      text-underline-offset: -0.12em;

      &:hover {
        text-decoration-color: $accent;
      }
    }
  }

  &.cs-style2 {
    text-align: right;

    .cs-case_study_bg {
      flex-direction: row-reverse;
    }
  }
}

.cs-faq_nav {
  height: 100%;
  background-color: #000;
  padding: 45px 35px;

  .cs-faq_nav_title {
    font-size: 30px;
  }
}

.swiper-slide {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  overflow: hidden;

  .cs-entity_img {
    display: none;
  }
}

.cs-parallax_slider {
  transition: opacity 0.6s ease, transform 0.3s ease;

  &.loading {
    opacity: 0;
    visibility: hidden;
  }
}

.cs-half_of_full_width {
  width: calc(55vw + 30%);
}

.cs-error_page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cs-filter_menu.cs-style1 {
  li {
    margin-top: 10px;

    &:not(:last-child) {
      margin-right: 28px;
    }
  }

  span {
    display: inline-block;
    position: relative;
    padding-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease;

    &::before {
      content: "";
      height: 9px;
      width: 9px;
      position: absolute;
      left: 50%;
      margin-left: -5px;
      border-radius: 50%;
      background-color: $accent;
      bottom: -5px;
      transition: all 0.4s ease;
      transform: scale(0);
    }
  }

  ul {
    border-bottom: 1px solid $ternary;
    flex-wrap: wrap;
    display: inline-flex;
    justify-content: flex-start;
  }

  .active {
    span {
      color: $accent;

      &::before {
        transform: scale(1);
      }
    }
  }
}

.cs-masonry_4_col {
  column-count: 4;
  column-gap: 25px;
  padding: 0 25px;

  > * {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 1540px) {
  .cs-case_study .cs-case_study_title {
    font-size: 100px;
    padding: 40px 120px;
  }
}

@media screen and (max-width: 1550px) {
  .cs-shape_wrap_2 .cs-shape_2 {
    right: -15%;
    top: 8%;
  }

  .cs-video_block.cs-style1 {
    height: 650px;
  }
}

@media screen and (max-width: 1400px) {
  .cs-case_study .cs-case_study_title {
    font-size: 80px;
    padding: 40px 100px;
  }
}

@media screen and (max-width: 1380px) {
  .cs-section_heading.cs-style1 .cs-section_title {
    font-size: 42px;
  }

  .cs-font_50 {
    font-size: 42px;
  }

  .cs-shape_wrap_4 .cs-shape_4:nth-child(2) {
    height: 200px;
    width: 200px;
  }

  .cs-page_heading.cs-style1 {
    height: 550px;
  }
}

@media screen and (max-width: 1199px) {
  .cs-page_heading.cs-style1 {
    height: 450px;
  }

  .cs-half_screen {
    width: 100%;
  }

  .cs-video_block.cs-style1.cs-size1 {
    height: 450px;
  }

  .cs-case_study .cs-case_study_title {
    font-size: 60px;
  }

  .cs-masonry_4_col {
    column-count: 2;
  }

  .cs-half_of_full_width {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .cs-section_heading.cs-style1 {
    .cs-section_title {
      font-size: 36px;
    }

    .cs-section_subtitle {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  .cs-shape_wrap_4 .cs-shape_4:nth-child(1),
  .cs-shape_wrap_4 .cs-shape_4:nth-child(2),
  .cs-shape_wrap_2 .cs-shape_2,
  .cs-shape_wrap_3 .cs-shape_3,
  .cs-shape_wrap_6 .cs-shape_2 {
    display: none;
  }

  .cs-font_50 {
    font-size: 36px;
  }

  .cs-font_38 {
    font-size: 32px;
  }

  .cs-video_block.cs-style1,
  .cs-video_block.cs-style1.cs-size1 {
    height: 400px;

    .cs-player_btn {
      transform: translate(-50%, -50%) scale(0.8);
    }
  }

  .cs-page_heading.cs-style1 {
    padding: 100px 0 50px;
    height: 400px;

    .cs-page_title {
      margin-bottom: 15px;
    }
  }

  .cs-portfolio_1_heading {
    .cs-tabs_heading {
      display: block;
    }

    .cs-section_heading + .cs-filter_menu {
      margin-top: 30px;
    }
  }

  .cs-list.cs-style1 li:not(:last-child) {
    margin-bottom: 10px;
  }

  .cs-google_map {
    height: 400px;
  }

  .cs-btn.cs-style1 {
    padding: 10px 20px;
  }

  .cs-faq_nav {
    padding: 30px 30px;

    .cs-faq_nav_title {
      font-size: 26px;
    }
  }

  .cs-google_map.cs-type1 {
    width: 100%;
    height: 400px;
    border-radius: 15px;
    overflow: hidden;
  }

  .cs-scrollup {
    right: 15px;

    &.cs-scrollup_show {
      bottom: 50px;
    }
  }

  .cs-column_reverse_lg {
    flex-direction: column-reverse;
  }

  .cs-case_study {
    position: relative;
    padding: 80px 70px;

    .cs-case_study_title {
      font-size: 56px;
      padding: 25px 45px;
    }
  }

  #cs-result {
    line-height: 2.2em;
  }

  .cs-half_of_full_width {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .cs-tabs.cs-style1 {
    .cs-tabs_heading {
      display: block;
    }

    .cs-section_heading + .cs-tab_links {
      margin-top: 50px;
    }
  }

  .cs-case_study {
    padding: 80px 12px;

    .cs-case_study_title {
      font-size: 40px;
      padding: 25px 25px;
    }
  }
}

@media screen and (max-width: 575px) {
  .cs-partner_logo {
    padding: 5px;
  }

  .cs-image_layer.cs-style1 {
    padding-right: 40px;
    padding-bottom: 40px;

    .cs-image_layer_in::after {
      right: -30px;
      bottom: -30px;
    }
  }

  .cs-video_block.cs-style1,
  .cs-video_block.cs-style1.cs-size1 {
    height: 380px;

    .cs-player_btn {
      transform: translate(-50%, -50%) scale(0.7);
    }
  }

  .cs-partner_logo_wrap {
    margin: 0 -10px;
  }

  .cs-partner_logo {
    padding: 10px;
  }

  .cs-case_study .cs-case_study_title {
    font-size: 30px;
  }

  .cs-page_heading.cs-style1 {
    .breadcrumb {
      justify-content: center;
    }
  }

  .cs-masonry_4_col {
    column-count: 1;
  }
}
